<template >
<footer><img src="~@/assets/github-mark-white.png" width="27" height="27"/>   Give us a Star on GitHub: <a href="https://github.com/senadmustafi/AnalyzeMe">AnalyzeMe</a>

</footer>
</template> 

<style >
footer{
  padding-bottom: 30px;
  display:inline-block;

}
</style>
<script>
export default{
    name:"FuturComp",
}

</script>